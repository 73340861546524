import * as React from "react"
import InnerLayout from "../components/global/innerLayout"
import "./403.scss"

const NotAllowedPage = () => {
    let seomatic = {
        metaTitleContainer: "<title>403 - Access Denied | Chicken Soup for the Soul</title>"
    }
    return (
        <InnerLayout seomatic={seomatic}>
            <div className="midd not-allowed-page">
                <div className="content-page">
                    <div className="container-small">
                        <div className="content-colum-row">
                            <div className="content-right">
                                <div className="content">
                                    <h1>403 - This page requires authorized access</h1>
                                    <p><strong>You are not authorized to view this page.</strong></p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </InnerLayout>
    )
}

export default NotAllowedPage
